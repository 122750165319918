import React from 'react'
import { Link } from 'react-router-dom';
import tw from 'twin.macro'


const BackGround = tw.div`flex justify-center items-center w-full h-[13rem] bg-[#F0F4DB]`;
const Logo = tw.img``;

const Footer = () => {
    return (
        <BackGround>
            <Link to="/"><Logo src="/images/vino-buenos-aires-logo-footer.svg" alt="logo vinos buenos aires footer" /></Link>
        </BackGround>
    )
}

export default Footer