import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Button = styled.a`
    ${tw`inline-flex justify-center items-center border-[0.1rem] rounded-[0.4rem] px-[2.8rem] py-[1rem] h-[3.4rem] font-bold text-[1.2rem] duration-500`}
    border-color: ${({ textcolor }) => textcolor};
    color: ${({ textcolor }) => textcolor};
    
    &:hover {
        background-color: ${({ textcolor }) => textcolor};
        color: ${({ bgcolor }) => bgcolor};
        cursor: pointer;
    }
`;

const ButtonLoad = styled.a`
    ${tw`inline-flex justify-center items-center border-[0.1rem] rounded-[0.4rem] px-[2.8rem] py-[1rem] h-[3.4rem] inline-block font-bold text-[1.2rem] duration-500`}
    border-color: ${({ textcolor }) => textcolor};
    color: ${({ textcolor }) => textcolor};
    
    &:hover {
        background-color: ${({ textcolor }) => textcolor};
        color: ${({ bgcolor }) => bgcolor};
        cursor: pointer;
    }
`;

const ButtonOutLine = ({ 
    url, 
    path,
    text, 
    textcolor = "black", 
    bgcolor = "white",
    onClick, // Añadir la propiedad onClick
    disabled = false // Añadir la propiedad disabled
}) => {
    const buttonProps = {
        bgcolor,
        textcolor,
        onClick,
        disabled
    };

    if (!path && !url) {
        // Si no hay path ni url, retorna un botón simple
        return (
            <ButtonLoad {...buttonProps}
                type="button"
                onClick={onClick} // Asegúrate de añadir el onClick aquí
                disabled={disabled} // También pasar el estado disabled
            >
                {text}
            </ButtonLoad>
        );
    }

    return path ? (
        // Si existe un path, utiliza Link para la navegación interna
        <Button as={Link} to={path} {...buttonProps}>
            {text}
        </Button>
    ) : (
        // Si no existe un path, utiliza un <a> para enlaces externos
        <Button href={url} target="_blank" rel="noopener noreferrer" {...buttonProps}>
            {text}
        </Button>
    );
}

export default ButtonOutLine;
