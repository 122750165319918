import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ContainerLayout } from '../../themes/Layouts';

// Usar la prop transitoria $bgimg para evitar que se pase al DOM
const HeroContainer = styled.div`
    ${tw`flex items-center mb-[6rem] md:mb-[9rem] h-[58.8rem] md:h-[73rem] 2xl:h-[80rem]`}
    background-size: cover;
    background-position: bottom center;
`;

const Container = tw(ContainerLayout)`text-center grid grid-cols-4 md:grid-cols-12 gap-[4rem]`;
const Content = tw.div`col-span-3 md:col-span-6`;
const FullContent = tw.div`col-span-4 md:col-span-12`;
const ImgHero = tw.img`mb-4`;
const Title = tw.h1`uppercase font-aoboshi text-start text-[2.8rem] md:text-[7.6rem] text-[#E6EFB6]`;
const LeyNumber = tw.p`text-start text-[white] text-[2.5rem] font-medium`;
const Subtitle = tw.p`font-semibold text-[1.6rem] md:text-[2rem] text-[#E6EFB6] text-start`;

const Hero = ({ imgbg, logo, title, ley, subtitle }) => {
    return (
        <HeroContainer style={{ backgroundImage: `url(${imgbg})` }}>
            <Container>
                {logo ? (
                    <Content>
                        {logo && <ImgHero src={logo} alt={title} />}
                        {title && <Title>{title}</Title>}
                        {ley && <LeyNumber>{ley}</LeyNumber>}
                        {subtitle && <Subtitle>{subtitle}</Subtitle>}
                    </Content>
                ) : (
                    <FullContent>
                        {title && <Title>{title}</Title>}
                        {ley && <LeyNumber>{ley}</LeyNumber>}
                    </FullContent>
                )}
            </Container>
        </HeroContainer>
    );
};

export default Hero;
