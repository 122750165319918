import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade'; // Importa el estilo del efecto fade

import './styles.css';

// import required modules
import { Pagination, Autoplay, EffectFade } from 'swiper/modules';

const Slider = ({ slideData }) => {

    if (slideData.length <= 0) {
        return <img className='w-full' src="images/slider-encuentro-vitivinicola/encuentro-vitivinicola.jpg" alt="cristian" />
    }

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    };

    return (
        <>
            <Swiper
                pagination={pagination}
                modules={[Pagination, Autoplay, EffectFade]} // Agrega los Modulos
                className="mySwiper"
                autoplay={{
                    delay: 3000, 
                    disableOnInteraction: false, // Mantener autoplay después de la interacción
                }}
                effect="fade" // Aplica el efecto fade
                fadeEffect={{ crossFade: true }} // Configuración adicional para el fade
                >
                {slideData.map((slide, index) => (
                    <SwiperSlide key={index}>
                        <img
                            className='w-full rounded-[0.4rem]'
                            src={slide}
                            alt={slide}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

export default Slider;
