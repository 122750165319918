import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Hero from '../components/hero/Hero';
import { fetchBodegas } from '../api/apiData';
import { ContainerLayout } from '../themes/Layouts';
import CardBodega from '../components/cards/CardBodega';
import SEO from '../components/SEO';
import ReactGA from "react-ga4";

const Bodegas = () => {

  ReactGA.send({
		hitType:"pageview",
		page: "/bodegas",
	});

  const [bodegas, setBodegas] = useState([]);

  useEffect(() => {
    fetchBodegas().then(data => {
      setBodegas(data);
    }).catch(error => {
      console.error('Error al cargar las bodegas:', error);
    });
  }, []);

  // Mostrar un mensaje de "cargando" si no hay bodegas aún
  if (bodegas.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <SEO
          title="Vino Buenos Aires | Bodegas" 
          description="Conocé las historias, anécdotas y tradiciones de los principales productores vitivinícolas de Buenos Aires que incentivan el enoturismo en la provincia."
          name="Vino Buenos Aires."
          type="website"
      />
      <Hero
        imgbg="./images/hero-bodegas.jpg"
        title="BODEGAS"
      />
      <ContainerLayout className='grid grid-cols-4 md:grid-cols-12 gap-x-[1.6rem] md:gap-x-[4rem] gap-y-[5rem] mb-[8rem] md:mb-[10rem]'>
        {bodegas.map((bodega, index) => (

          <div key={index} className='col-span-2 md:col-span-4'>
            <CardBodega 
              title={bodega.name}
              img={`images/bodegas/${bodega.imgCard}`} 
              path={`/bodegas/${bodega.slug}`}
              />
          </div>

        ))}
      </ContainerLayout>
    </>
  );
};

export default Bodegas;