import React from 'react';
import { motion } from 'framer-motion'; // Importa motion de framer-motion
import ButtonOutLine from '../buttons/ButtonOutLine';
import { Link } from 'react-router-dom';

const CardNewHome = ({ img, title, linkText, path, url, primary }) => {
    if (primary === true) {
        return null; // No renderiza nada si primary es true para que no se repita la noticia si ya se cargó como principal
    }

    // Animaciones para la tarjeta
    const cardVariants = {
        hidden: { opacity: 0, y: 50 }, // Aparece de abajo
        visible: { opacity: 1, y: 0 }, // Se coloca en la posición final
    };

    return (
        <motion.div
            className='flex flex-col items-start justify-between md:h-[45.5rem] 2xl:h-[52rem] border-b-[0.1rem] border-[#C9B066] md:border-none'
            variants={cardVariants}
            initial="hidden"
            whileInView="visible" // Se activa la animación cuando entra en vista
            viewport={{ once: false, amount: 0.2 }} // Controla cuándo se inicia la animación, amount=20%
            transition={{ duration: 0.8 }} // Duración de la animación
        >
            <div className='w-full'>
                <motion.img 
                    src={img} 
                    alt={title} 
                    className='mb-[1.6rem] w-[100%] rounded-[0.4rem]' 
                    style={{ opacity: 0.9 }} // Opacidad inicial de la imagen
                    whileHover={{ opacity: 1 }} // Cambia a opacidad 1 al hacer hover
                    transition={{ duration: 0.3 }} // Duración de la transición en hover
                />
                <h3 className='block font-raleway font-semibold text-[#373737] text-[1.4rem] leading-[1.6rem] md:text-[2.2rem] md:leading-[2.5rem] mb-[1.6rem]'>
                    {title}
                </h3>
            </div>
            <div className='hidden md:block'>
                <ButtonOutLine
                    text={linkText}
                    path={path}
                    url={url}
                    textcolor="#C9B066"
                    bgcolor="white"
                />
            </div>
            <a href={url} target='_blank' className='ml-auto mb-[2rem] font-bold text-[1rem] text-[#B47D02] pe-[0.2rem] md:hidden'>Leer más</a>
        </motion.div>
    );
};

export default CardNewHome;
