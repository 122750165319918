import React from 'react'

const CardReview = ({ review }) => {
    
    return (
        <div className='flex flex-col justify-between items-center text-center text-[#373737] rounded-[0.4rem]'>
            <img className='mb-[3.5rem] w-[2.3rem] md:w-[4.6rem]' src='/images/comillas.svg' alt='icono comillas' />
            <p className='font-semibold text-[1.3rem] leading-[1.5rem] md:text-[2.2rem] md:leading-[2.5rem] mb-[4rem]'>{review.coment}</p>
            <div className='flex flex-col justify-center items-center'>
                {/* <div className='h-[2px] w-[16rem] bg-[#B47D02] mb-[3rem]'></div> */}
                <span className='text-[1.6rem] font-semibold'>{review .name}</span>
                <span className='text-[1.4rem] font-medium'>{review .bodega}</span>
            </div>
        </div>
    )
}

export default CardReview