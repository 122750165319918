import React from 'react';
import tw from 'twin.macro';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

// Importa los iconos de SocialIcons
import { FacebookIcon, TwitterIcon, WhatsappIcon } from './ShareIcons'; // Asegúrate de que la ruta sea correcta

const ContainerIcon = tw.div``;

const ShareButtons = ({ title, shareUrl }) => {
  return (
    <div className='inline-flex items-center gap-x-[2rem] mx-[2rem]'>
      <FacebookShareButton url={shareUrl} quote={title}>
        <div className='flex justify-center items-center bg-[#C9B066] hover:bg-[#B47D02] h-[2.3rem] w-[2.3rem] rounded-full hover:transition-all duration-500'>
        <WhatsappIcon/>
        </div>
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={title}>
        <div className='flex justify-center items-center bg-[#C9B066] hover:bg-[#B47D02] h-[2.3rem] w-[2.3rem] rounded-full hover:transition-all duration-500'>
          <TwitterIcon/>
        </div>
      </TwitterShareButton>
      <WhatsappShareButton url={shareUrl} title={title}>
        <div className='flex justify-center items-center bg-[#C9B066] hover:bg-[#B47D02] h-[2.3rem] w-[2.3rem] rounded-full hover:transition-all duration-500'>
          <FacebookIcon/>
        </div>
      </WhatsappShareButton>
    </div>
  );
};

export default ShareButtons;
