import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import ReactGA from "react-ga4";

const HeroContainer = styled.div`
    ${tw`flex flex-col items-center justify-end pb-[8rem] md:pb-[8rem] mb-[6rem] md:mb-[9rem] h-[58.8rem] md:h-[73rem] 2xl:h-[80rem]`}
    
    background-image: url('/images/hero-event-small.jpg'); /* Imagen para mobile */
    background-size: cover;
    background-position: top center;

    @media (min-width: 540px) {
        /* Cambia la imagen cuando el ancho sea mayor o igual a 768px (md) */
        background-image: url('/images/hero-event-tablet.jpg'); /* Imagen para desktop */
        background-position: center center;
    }

    @media (min-width: 1024px) {
        /* Cambia la imagen cuando el ancho sea mayor o igual a 768px (md) */
        background-image: url('/images/hero-event.jpg'); /* Imagen para desktop */
        background-position: center center;
    }
`;

const ButtonEvent = styled.a`
    ${tw`bg-[#e19500] text-white border-none inline-flex justify-center items-center rounded-[0.4rem] px-[2.8rem] py-[1rem] h-[4rem] font-semibold text-[1.5rem] transition-transform duration-500 ease-in-out opacity-[0.9]`}
    
    &:hover {
        ${tw`scale-[1.1] opacity-[1]`}
    }
`;

const HeroEvent = () => {

    // Función para registrar el evento de clic en los botones de "Explorar mapa"
    const handleDownLoadPhoto = () => {
        ReactGA.event({
            category: 'Botón',
            action: 'Descargar Foto',
            label: 'Descarga tu foto'
        });
    };

    return (
        <HeroContainer>
            <ButtonEvent
                href="https://forms.gle/MoR9jJhib2TXGEpG7"
                target='_blank'
                onClick={handleDownLoadPhoto} // Registro del clic descarga foto
            >
                Descargá tu foto
            </ButtonEvent>
        </HeroContainer>
    );
};

export default HeroEvent;
