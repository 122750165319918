import React from 'react'
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

const Container = tw.div`flex flex-col justify-center items-center hover:transition-all hover:underline hover:text-[#B47D02] duration-500`;
const Btn = tw(Link)`flex justify-center items-center h-[3.2rem] w-full md:w-[12rem] font-bold text-[#A57C0E] text-[1rem] md:text-[1.2rem] rounded-[0.4rem] border-[0.1rem] md:border-none border-[#B47D02] cursor-pointer`;

const ButtonLoadMore = ({ text, path}) => {
    return (
        <Container>
            <Btn to={path}>
                {text}
            </Btn>
        </Container>
    )
}

export default ButtonLoadMore