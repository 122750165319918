// SocialIcons.js
import React from 'react';

export const FacebookIcon = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2513 1.75477C9.12362 0.621105 7.62211 0 6.02412 0C2.73166 0 0.0482413 2.68342 0.0482413 5.97588C0.0482413 7.03116 0.325628 8.05628 0.844221 8.9608L0 12.0603L3.16583 11.2281C4.0402 11.7045 5.02311 11.9578 6.02412 11.9578C9.31658 11.9578 12 9.27437 12 5.98191C12 4.38392 11.3789 2.88241 10.2513 1.75477ZM6.02412 10.9447C5.13166 10.9447 4.25729 10.7035 3.49146 10.2513L3.31055 10.1427L1.42915 10.6372L1.92965 8.80402L1.80905 8.61709C1.31457 7.82714 1.04925 6.91055 1.04925 5.97588C1.04925 3.23819 3.2804 1.00704 6.01809 1.00704C7.34472 1.00704 8.59296 1.52563 9.52764 2.46633C10.4683 3.40704 10.9809 4.65528 10.9809 5.98191C10.993 8.7196 8.76181 10.9447 6.02412 10.9447ZM8.74975 7.23015C8.59899 7.15779 7.86332 6.79598 7.73065 6.74171C7.59196 6.69347 7.49548 6.66935 7.39296 6.81407C7.29045 6.96482 7.00703 7.30251 6.92261 7.39899C6.83819 7.50151 6.74774 7.51357 6.59698 7.43518C6.44623 7.36281 5.96382 7.2 5.39698 6.69347C4.95075 6.29548 4.65528 5.80703 4.56482 5.65628C4.4804 5.50553 4.55276 5.42714 4.63116 5.34874C4.69749 5.28241 4.78191 5.17387 4.85427 5.08945C4.92663 5.00502 4.95678 4.93869 5.00502 4.84221C5.05327 4.7397 5.02914 4.65528 4.99296 4.58291C4.95678 4.51055 4.65528 3.77487 4.53467 3.47337C4.41407 3.18392 4.28744 3.2201 4.19698 3.21407C4.10653 3.21407 4.01005 3.21407 3.90754 3.21407C3.80502 3.21407 3.64824 3.25025 3.50955 3.401C3.37688 3.55176 2.99095 3.91357 2.99095 4.64925C2.99095 5.38492 3.52764 6.09648 3.6 6.19297C3.67236 6.29548 4.65528 7.80302 6.15075 8.44824C6.50653 8.60503 6.78392 8.69548 7.001 8.76181C7.35678 8.87638 7.68241 8.85829 7.94171 8.82211C8.23115 8.7799 8.82814 8.4603 8.94874 8.11055C9.07538 7.7608 9.07538 7.46533 9.03316 7.39899C8.99095 7.33266 8.9005 7.30251 8.74975 7.23015Z" fill="white"/>
  </svg>
);

export const TwitterIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.1875 0.25L4.485 6.41184L0.413435 11.1875H2.01272L5.20066 7.43842L7.81488 11.1875H12L7.50496 4.73134L11.3151 0.25H9.74065L6.79166 3.70595L4.38563 0.25H0.1875ZM2.51309 1.46528H3.75396L9.67559 9.97222H8.44537L2.51309 1.46528Z" fill="white"/>
  </svg>
);

export const WhatsappIcon = () => (
  <svg width="6" height="13" viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.53166 13V6.90002H0V4.70374H1.53166V2.82783C1.53166 1.35372 2.43642 0 4.52117 0C5.36526 0 5.98942 0.085215 5.98942 0.085215L5.94024 2.13617C5.94024 2.13617 5.3037 2.12965 4.60907 2.12965C3.85727 2.12965 3.73683 2.49449 3.73683 3.10004V4.70374H6L5.90153 6.90002H3.73683V13H1.53166Z" fill="white"/>
  </svg>
);
