import React, { useEffect } from 'react'; // Importa useEffect
import ReactGA from "react-ga4";

const NotFound = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/*",
            title: "Pagina no encontrada",
        });
    }, []); 

    return (
        <div>NotFound</div>
    );
}

export default NotFound;
