import React from 'react'
import tw from 'twin.macro'
import { ContainerLayout } from '../../themes/Layouts';

const Container = tw(ContainerLayout)``;
const Title = tw.h2`font-raleway text-[2.2rem] md:text-[4rem] text-[#B47D02] font-bold ps-[1.5rem] md:ps-[0rem]`;
const Line = tw.div`bg-[#C9B066] h-[0.2rem] absolute w-[100%] -translate-y-[50%]`;
const Circle = tw.div`bg-[#C9B066] h-[1rem] w-[1rem] rounded-full absolute -translate-y-[50%] md:-translate-x-[5rem]`;

const TitleSection = ({text}) => {
    return (
        <div className='border-b-[0.1rem] border-[#C9B066] relative w-[100%]'>
            <Container>
                <Title>{text}</Title>
                <Circle/>
            </Container>
            
        </div>

    )
}

export default TitleSection