import React from 'react'
import tw from 'twin.macro'

const Card = tw.div`
    flex flex-col justify-between items-center h-[45rem] bg-white rounded-[0.4rem] shadow-md
    font-semibold text-center text-[#373737] text-[1.8rem]
    my-[1rem] mx-[0.2rem]
    pt-[5rem] pb-[4rem] px-[2rem]
    md:px-[3rem]`;

const Msj = tw.p`font-semibold text-[2.2rem]`;

const Line = ({ color }) => (
    <div style={{ height: '1px', width: '16rem', backgroundColor: color || '#B47D02', marginBottom: '3rem' }} />
);

const Comillas = ({ color }) => (
    <svg style={{ color }} width="40" height="40" viewBox="0 0 48 40" xmlns="http://www.w3.org/2000/svg">
        <path  fill={color} d="M27.3516 21.4053V0.112305H47.8359V18.2383C47.8359 29.4238 42.2881 36.6113 31.1924 39.8008L27.4863 32.9951C30.7207 31.8721 33.1016 30.5244 34.6289 28.9521C36.1562 27.335 36.9199 24.8193 36.9199 21.4053H27.3516ZM0.533203 21.4053V0.112305H20.9502V18.2383C20.9502 29.4238 15.4023 36.6113 4.30664 39.8008L0.600586 32.9951C3.83496 31.8721 6.21582 30.5244 7.74316 28.9521C9.27051 27.335 10.0342 24.8193 10.0342 21.4053H0.533203Z" />
    </svg>
);

const CardQuote = ({ story, name, bodega, color }) => {
    return (
        <Card>
            <Comillas color={color}/>
            <Msj>{story}</Msj>
            <div className='flex flex-col justify-center items-center'>
                <Line color={color} />
                <span className='text-[1.6rem] font-semibold'>{name}</span>
                <span className='text-[1.4rem] font-medium'>{bodega}</span>
            </div>
        </Card>
    )
}

export default CardQuote