import React from 'react'
import tw from 'twin.macro';
import { ContainerLayout } from '../../themes/Layouts'
import Separator from './Separator';

const Container = tw(ContainerLayout)`flex flex-col justify-center items-center mb-[7.5rem] md:mb-[10rem]`;

const ContactBodegas = ({contactData}) => {

    if (contactData[0].link < 1 ) {
        return null;
    }
    return (
        <Container>
            <h2 className='font-bold text-[#C9B066] text-[1.6rem] md:text-[2.2rem]'>Contacto</h2>
            <div className='mb-[1.6rem] md:mb-[5.5rem]'>
                {contactData.map((contact, index) => (
                    <a key={index} href={`https://www.instagram.com/${contact.link}`} target='_blank' className='flex items-center gap-[1rem] font-medium text-[#373737] text-[1.3rem] md:text-[1.6rem] mb-[0.5rem]'>
                        <div className='flex justify-center items-center bg-[#C9B066] h-[3rem] w-[3rem] rounded-full'><img src='/images/icon-instagram.svg' alt='icono de instagram'/></div>
                        {contact.text}
                    </a>
                ))}
            </div>
            <Separator/>
        </Container>
    )
}

export default ContactBodegas