import React from 'react';

const ButtonLoadMoreAnimate = ({
        text,
        onClick, // Añadir la propiedad onClick
        disabled = false // Añadir la propiedad disabled
    }) => {
    return (
        <button 
            type="button" 
            className={`h-[3.2rem] text-[#A57C0E] font-bold text-[1.2rem] py-2 px-8 rounded inline-flex items-center ${disabled ? 'cursor-not-allowed' : 'hover:text-[##C9B066] hover:underline'}`} // Asegúrate de usar className y estilos según disabled
            onClick={onClick} // Asegúrate de añadir el onClick aquí
            disabled={disabled} // También pasar el estado disabled
        >
            {text}
            {disabled && (
                <svg className="animate-spin -mr-1 ml-3 h-6 w-6 text-[#C9B066]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            )}
        </button>
    );
}

export default ButtonLoadMoreAnimate;
