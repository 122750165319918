import React from 'react'
import tw from 'twin.macro'
import Hero from '../components/hero/Hero'
import { ContainerLayout } from '../themes/Layouts'

import SEO from '../components/SEO';
import ReactGA from "react-ga4";
import MoreNews from '../components/section/MoreNews';
import TitleSection from '../components/section/TitleSection';

const Container = tw(ContainerLayout)`grid grid-cols-4 md:grid-cols-12 md:gap-x-[4rem] pb-[11.5rem]`;
const CategoryLabel = tw.span`col-span-4 md:col-span-8 font-medium text-[#B47D02] uppercase text-[1.2rem] mb-[2rem]`;
const Title = tw.h2`col-span-4 md:col-span-8 font-bold text-[#373737] text-[2.2rem] leading-[2.4rem] md:text-[4rem] md:leading-[4.2rem] mb-[1.6rem]`;
const Date = tw.span`col-span-4 md:col-span-8 font-medium text-[#979797] text-[1.2rem] mb-[1.5rem] md:mb-[2.5rem]`;
const Resume = tw.p`col-span-4 md:col-span-8 font-medium text-[#373737] text-[1.3rem] leading-[1.5rem] md:text-[2.2rem] md:leading-[2.4rem] mb-[4rem]`;
const ImgNew = tw.img`w-full col-span-4 md:col-span-8 rounded-[0.4rem] mb-[4.5rem]`;
const TextNew = tw.p`col-span-4 md:col-span-8 font-normal text-[#373737] text-[1.3rem] md:text-[1.4rem] mb-[2.5rem]`;

const NoticiaDestacada = () => {

    ReactGA.send({
		hitType:"pageview",
		page: "/graduados-curso-sommelier",
	});

    return (
        <>
            <SEO
                title="Vino Buenos Aires | Noticias" 
                description="Graduados del curso de sommelier de Malvinas participaron del 7mo Encuentro de Productores Vitivinícolas"
                name="Vino Buenos Aires."    
                type="website"
            />

            <Hero imgbg="./images/hero-news.jpg" title="NOTICIAS" />

            <Container>
                <CategoryLabel>EVENTO</CategoryLabel>
                <Title>
                    Graduados del curso de sommelier de Malvinas participaron del 7mo Encuentro de Productores Vitivinícolas
                </Title>
                <Date>Lunes 04 de Noviembre de 2024</Date>
                <Resume>
                    Los graduados del Centro Municipal de Estudios realizaron una cata, evaluaron y votaron al mejor vino de la jornada.
                </Resume>
                <ImgNew src='./images/news/encuentro_productores_vitivinicolas.jpg' alt='Graduados del curso de sommelier de Malvinas participaron del 7mo Encuentro de Productores Vitivinícolas'/>
                <TextNew>
                    Los estudiantes y graduados del curso de #Sommelier de la Escuela Municipal de Estudios participaron de las jornadas realizando la cata y evaluación de los distintos vinos de la Provincia de Buenos Aires, quienes a través de la votación, destacaron a los siguientes vinos y bodegas:
                </TextNew>
                <TextNew>
                    Mejor Vino: Ventania Cabernet Franc (Bodega Saldungaray)
                </TextNew>
                <TextNew>
                    Además, se le entregó un reconocimiento especial a Daniel Di Nucci, representante de “Bodegas Al Este”, por ser el primer bodeguero en establecerse en la Provincia de Buenos Aires, luego de la derogación de la Ley que prohibía la actividad vitivinícola en la zona.
                </TextNew>
            </Container>

            <TitleSection text="Más Noticias"/>
            <MoreNews/>
        </>

    )
}

export default NoticiaDestacada