import React from 'react';
import { motion } from 'framer-motion'; // Importa motion de framer-motion
import ButtonOutLine from '../buttons/ButtonOutLine';

const CardNewPrimary = ({ img, title, detail, linkText, path, url, date }) => {
    
    // Función para formatear la fecha
    const formatDate = (dateString) => {
        const date = new Date(`${dateString}T00:00:00`);
        return date.toLocaleDateString('es-ES', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };

    // Definición de las animaciones
    const cardVariants = {
        hidden: { opacity: 0, y: 50 }, // Aparece desde abajo
        visible: { opacity: 1, y: 0 }, // Se coloca en la posición final
    };

    return (
        <motion.div
            className='grid grid-cols-4 md:grid-cols-12 gap-[2rem] md:gap-[4rem]'
            variants={cardVariants}
            initial="hidden"
            whileInView="visible" // Se activa la animación cuando entra en vista
            viewport={{ once: false, amount: 0.2 }} // Se activa cuando el 20% del componente está visible
            transition={{ duration: 0.8 }} // Duración de la animación
        >
            <img 
                src={img} 
                alt={title} 
                className='aspect-[16/9] object-cover w-[100%] col-span-4 md:col-span-6 order-1 md:order-2 rounded-[0.4rem]' 
            />
            <div className='col-span-4 md:col-span-6 order-2 md:order-1'>
                <h3 className='block font-raleway font-bold text-[#373737] text-[2.2rem] mb-[1rem] leading-[2.2rem] md:text-[4rem] md:leading-[4rem] md:mb-[1.5rem]'>
                    {title}
                </h3>
                <span className='block font-medium text-[1.2rem] text-[#979797] mb-[1.2rem] md:mb-[2rem]'>
                    {formatDate(date)}
                </span>
                <p className='font-semibold text-[1.3rem] md:text-[1.4rem] text-[black] mb-[1.2rem] md:mb-[4rem]'>
                    {detail}
                </p>
                <ButtonOutLine
                    text={linkText}
                    path={path}
                    url={url}
                    textcolor="#C9B066"
                    bgcolor="white"
                />
            </div>
        </motion.div>
    );
};

export default CardNewPrimary;
