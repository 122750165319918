import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, name, type, img }) => {
    return (
        <Helmet>
        {/* Meta title */}
        <title>{title}</title>

        {/* Meta description */}
        <meta name="description" content={description} />

        {/* OpenGraph meta tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content={type} />
        <meta property="og:site_name" content={name} />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:site" content={name} />
        <meta name="twitter:image" content={img} />
        </Helmet>
    );
};

export default SEO;
