import React from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga4'; // Importar ReactGA

const Card = tw(motion.article)`relative h-[100%] border-b-[0.1rem] border-[#C9B066] md:border-none overflow-hidden`;
const ImageWrapper = tw.div`relative`;
const ImageCard = tw.img`aspect-[4/3] font-raleway object-cover w-full rounded-[0.4rem] mb-[1rem] md:mb-[1.5rem] transition-all duration-500 ease-in-out`;
const Title = tw.h2`capitalize text-[#B47D02] font-bold text-[1.6rem] md:text-[2.2rem] mb-[0rem] md:mb-[1rem]`;
const Line = tw.div`hidden md:block h-[0.2rem] bg-[#C9B066] grid-cols-subgrid col-span-3 md:w-[75%]`;

const Overlay = tw.div`absolute inset-0 bg-[rgba(180,125,2,0.41)] opacity-0 transition-opacity duration-500 ease-in-out z-10 rounded-[0.4rem]`;
const HoverButton = tw.div`absolute inset-0 flex justify-center items-center text-white font-bold text-[1.2rem] md:text-[1.4rem] opacity-0 transition-all duration-500 ease-in-out group-hover:border-[0.1rem] group-hover:border-white z-20 group-hover:opacity-100 group-hover:translate-y-0`;

const CardBodega = ({ title, img, path }) => {
    
    // Función para registrar el clic en Google Analytics
    const handleCardClick = () => {
        ReactGA.event({
            category: 'Bodega',   // Categoría del evento
            action: `Clic en tarjeta ${title}`, // Acción que el usuario realiza
            label: `Boton Bodega - ${title}`,         // Etiqueta que identifica a la bodega
        });
    };

    return (
        <Link to={path} className="group rounded-[0.4rem] overflow-hidden" onClick={handleCardClick}>
            <Card
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
            >
                <ImageWrapper>
                    <ImageCard src={img} alt={title} />
                    <Overlay className="group-hover:opacity-100" />
                    <HoverButton className="group-hover:opacity-100">Ver bodega</HoverButton>
                </ImageWrapper>
                <Title>{title}</Title>
                <Line />
            </Card>
        </Link>
    );
};

export default CardBodega;