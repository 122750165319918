import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Desplazamiento suave
            });
        }, 100); // Agrega un pequeño retraso si lo deseas, o puedes eliminar el timeout

        return () => clearTimeout(timer);
    }, [pathname]);

    return null;
}

export default ScrollToTop;
