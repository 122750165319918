import React from 'react'
import tw from 'twin.macro';
import { ContainerLayout } from '../../themes/Layouts';

const Container = tw(ContainerLayout)`grid grid-cols-12 relative z-10`;
const Line = tw.div`absolute bg-[#C9B066] h-[0.1rem] w-[100%] -translate-y-[50%] z-10`;
const Circle = tw.div`absolute bg-[#C9B066] h-[0.6rem] w-[0.6rem] rounded-full -translate-y-[50%] left-[50%] -translate-x-[50%] z-10`;

const Separator = () => {
    return (
        <Container>
            <div className='col-span-6 col-start-4 h-[1rem] relative w-[100%]'>
                <Circle/>
                <Line/>
            </div>
        </Container>
    )
}

export default Separator