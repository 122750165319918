import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import { fetchNews } from '../../api/apiData';
import { ContainerLayout } from '../../themes/Layouts';
import CardNewHome from '../cards/CardNewHome';
import ButtonLoadMore from '../buttons/ButtonLoadMore';

const Container = tw(ContainerLayout)`grid grid-cols-4 md:grid-cols-12 overflow-hidden`;

const MoreNews = () => {
    const [news, setNews] = useState([]);
    const [visibleNewsCount, setVisibleNewsCount] = useState(3);

    useEffect(() => {
        fetchNews().then(data => {
            setNews(data.reverse()); // Invertimos el array de noticias para mostrar las más recientes primero
        });
    }, []);

    return (
        <Container className="gap-[4rem] pt-[6rem] mb-[4rem]">
            {news.slice(0, visibleNewsCount).map((item, index) => (
                <div className="col-span-4" key={index}>
                    <CardNewHome img={`/images/news/${item.img}`} title={item.title} linkText={item.link.text} url={item.link.url} path={item.link.path}/>
                </div>
            ))}
            <div className="col-span-4 md:col-span-12">
                <ButtonLoadMore
                    path="/noticias"
                    text="Ver más noticias"
                />
            </div>
        </Container>
    );
};

export default MoreNews;
